import { UserService } from '@abbott/abbott-api-client';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class ReadonlyUserAccessGuard implements CanActivate {
	constructor(
		private readonly router: Router,
		private readonly userService: UserService
	) {}

	canActivate() {
		if (
			!this.userService.authUser?.readonlyUserAccess &&
			!this.userService.authUser.roles.includes('admin')
		) {
			this.router.navigate([]);
			return false;
		}
		return true;
	}
}
