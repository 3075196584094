import { UserService } from '@abbott/abbott-api-client';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class DefaultRouteGuard implements CanActivate {
	constructor(private userService: UserService, private router: Router) {}

	canActivate() {
		const authUser = this.userService.authUser;
		if (!authUser) {
			this.router.navigate(['/login']);
			return false;
		}
		if (authUser.roles.includes('admin')) {
			this.router.navigate(['/admin/users']);
			return false;
		}
		this.router.navigate(['/clinician']);
		return false;
	}
}
